/**
 * Created by Tomasz Kotlarek (ZICHER) on 25.12.2017.
 */

define('application/main',[
  "application/options",
  "i18n!nls/text",
  "jquery",
  "application/moveToFragment",
  "application/backToTop",
  "application/menu",
  "application/contactForm"
], function (appOptions,
             text,
             $,
             appMoveToFragment,
             appBackToTop,
             appMenu,
             appContactForm) {
  var $window = $(window);
  var $document = $(document);
  var $html = $("html");
  var $body = $("body");

  var locale = $html.attr("lang");

  // TODO fix $document vs body vs dom issue with these functions

  appMoveToFragment($document);
  appBackToTop($document);
  appMenu($document);
  appContactForm($document);

  $document.trigger("app.domset", [{dom: $body}]);

  // run it when the page is already scrolled on refresh so the events based on scrolling can fire at load
  $window.trigger("scroll");
});
