/**
 * Created by Tomasz Kotlarek (ZICHER) on 25.12.2017.
 */

define ('application/backToTop',[],function () {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-back-to-top").on("click", function (event) {
        event.preventDefault();

        $document.trigger("app.moveToFragment", [{
          source: "click",
          $target: data.dom
        }]);
      });
    });

    return this;
  }
});
