/**
 * Created by Tomasz Kotlarek (ZICHER) on 28.08.2017.
 */

require.config({
  baseUrl: "main/js",
  paths: {
    //"propertyParser": "../../bower_components/requirejs-plugins/src/propertyParser",
    //"async": "../../bower_components/requirejs-plugins/src/async",
    //"goog": "../../bower_components/requirejs-plugins/src/goog",
    "jquery": [
      "//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min",
      "vendor/jquery/3.2.1/jquery.min"
    ],
    "js-cookie": [
      "//cdnjs.cloudflare.com/ajax/libs/js-cookie/2.2.0/js.cookie.min",
      "vendor/jscookie/2.2.0/js.cookie.min"
    ]
  },
  shim: {
    "jquery": {
      exports: "jQuery"
    }
  }
});

require(["application/main"]);
define("main", function(){});

