/**
 * Created by Tomasz Kotlarek (ZICHER) on 25.12.2017.
 */

define('application/moveToFragment',["application/options"], function (appOptions) {
  return function ($document) {
    $document.on("app.moveToFragment", function (event, data) {
      setTimeout(function () {
        var offset = data.$target.offset();

        if (offset) {
          var height = $(appOptions.moveToFragment.staticHeaderSelector).length > 0 ? $(appOptions.moveToFragment.staticHeaderSelector).height() : 0;
          var margin = parseInt(window.getComputedStyle(data.$target[0]).getPropertyValue("margin-top"));
          var scrollTo = offset.top - 67; //height;// - margin;
          var $htmlBody = $("html, body");

          $htmlBody.animate({scrollTop: scrollTo}, data.source === "load" ? 0 : 500, function () {
            $htmlBody.unbind("scroll mousedown DOMMouseScroll mousewheel keyup");
          });

          $htmlBody.bind("scroll mousedown DOMMouseScroll mousewheel keyup", function () {
            $htmlBody.stop().unbind("scroll mousedown DOMMouseScroll mousewheel keyup");
          });
        }
      }, 20);
    });

    $document.on("click", 'a[href^="#"]', function (event) {
      event.preventDefault();

      var $this = $(this);
      var href = $this.attr("href");
      var hash = href.substr(href.indexOf("#"));
      history.pushState(null, $this.text(), hash);

      $document.trigger("app.moveToFragment", [{
        source: "click",
        $target: $(hash)
      }]);
    });

    $document.trigger("app.moveToFragment", [{
      source: "load",
      $target: $(":target")
    }]);

    return this;
  }
});
