/**
 * Created by Tomasz Kotlarek (ZICHER) on 27.12.2017.
 */

define('application/menu',["jquery"], function ($) {
  var screenWidth;
  var screenHeight;

  var updateWidthHeight = function ($window) {
    screenWidth = $window.width();
    screenHeight = $window.height();
  };

  return function ($document) {
    $document.on("app.domset", function (event, data) {
      var $menuSlide = $(".js-menu-slide");

      data.dom.find(".js-menu-trigger").on("click", function () {
        $menuSlide.toggleClass("-active");
      });

      $menuSlide.on("click", function () {
        $(this).removeClass("-active");
      });

      var $menu = data.dom.find(".js-menu");

      $menu.on("click", function () {
        var $this = $(this);
        var $menu = $document.find($this.data("menu-target"));

        $menu.addClass("active-display");

        setTimeout(function () {
          $menu.toggleClass("active-visibility");
        }, 100);
      });

      var $window = $(window);

      updateWidthHeight($window);
      $window.on("resize", function () {
        updateWidthHeight($window);
      });

      $window.on("scroll", function () {
        var scroll = $window.scrollTop();

        if (scroll >= screenHeight && false === $menu.hasClass("js-menu-fixed")) {
          $menu.addClass("js-menu-fixed");
        } else if (scroll < screenHeight && $menu.hasClass("js-menu-fixed")) {
          $menu.removeClass("js-menu-fixed");
        }
      });
    });

    return this;
  }
});
