/**
 * Created by Tomasz Kotlarek (ZICHER) on 23.03.2018.
 */

define('application/contactForm',["jquery"], function ($) {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-contact-form").on("submit", function (event) {
        event.preventDefault();

        var $form = $(this);
        $form.css("filter", "blur(5px)");

        var formDataSerialized = $(this).serializeArray();
        var formDataObjectified = {};
        var formDataStringified;

        for (var i = 0; i < formDataSerialized.length; i++) {
          formDataObjectified[formDataSerialized[i].name] = formDataSerialized[i].value;
        }

        formDataStringified = JSON.stringify(formDataObjectified);

        $.ajax({
          method: "POST",
          url: "https://api.featureforest.pl/v1/contact-request",
          data: formDataStringified,
          contentType: "application/json",
          success: function () {
            $form.find(".a-submit").addClass("-success");
          },
          error: function (jqXHR) {
            if (jqXHR.status === 400) {

            } else if (jqXHR.status === 500) {

            } else {

            }

            $form.find(".a-submit").addClass("-error");
          },
          complete: function () {
            $form.css("filter", "blur(0)");
          }
        });
      });

      return this;
    });

    return this;
  }
});
